import type { ProductBasic, TaxRule } from "@repo/types";
import type { DiningOption } from "~/providers/store/basket";

type GetVatRateFromTaxRulesArgs = {
  product: ProductBasic;
  taxRules: TaxRule[] | undefined;
  diningOption: DiningOption | null;
};

type GetVatRateFromTaxRulesReturn = {
  vatRate: number;
  price: "incl" | "excl";
};

export function getVatRateFromTaxRules({
  product,
  taxRules,
  diningOption,
}: GetVatRateFromTaxRulesArgs): GetVatRateFromTaxRulesReturn {
  const defaultConfig: GetVatRateFromTaxRulesReturn = {
    vatRate: product.vatRate,
    price: "excl",
  };

  if (!taxRules?.length) return defaultConfig;

  // Get the tax rules for the selected dining option
  const taxOverwrites = taxRules.filter(
    (taxRule) =>
      taxRule.directives === null ||
      taxRule.directives.conditions?.[0]?.condition.inputId ===
        diningOption?.inputId
  );
  if (taxOverwrites.length === 0) return defaultConfig;

  const productTaxRules = product.taxRuleIds;

  // if the product has tax rules ids as undefined, find the taxRule that is set for all products
  if (typeof productTaxRules === "undefined") {
    const defaultTaxRule = taxOverwrites.find(
      (rule) =>
        (rule.products === "all" || rule.products === "all-default") &&
        (rule.directives === null ||
          rule.directives?.conditions[0].condition.option ===
            diningOption?.value)
    );

    if (!defaultTaxRule) {
      return defaultConfig;
    }

    return { vatRate: defaultTaxRule.vatRate, price: defaultTaxRule.price };
  }

  // if the product has tax rules ids as an empty array, return the product's vat rate
  if (!productTaxRules.length) {
    return defaultConfig;
  }

  // find the first tax rules from the product tax rules
  const productTaxRule = taxOverwrites.find((taxRule) =>
    productTaxRules.includes(taxRule.id)
  );

  if (productTaxRule) {
    // if directive is null, it means that the tax rule is always active
    // if directive is not null, it means that the tax rule is active only for specific conditions
    const hasDirectives = !productTaxRule.directives;
    const matchesDiningOption =
      productTaxRule.directives?.conditions[0].condition.option ===
      diningOption?.value;

    if (hasDirectives || matchesDiningOption)
      return { vatRate: productTaxRule.vatRate, price: productTaxRule.price };
  }

  // if the product has tax rules ids but it doesn't fit the condition above, find the taxRule (only from the required) that is set for all products
  const defaultTaxRule = taxOverwrites.find(
    (rule) =>
      rule.products === "all" &&
      (rule.directives === null ||
        rule.directives?.conditions[0].condition.option === diningOption?.value)
  );

  if (!defaultTaxRule) {
    return defaultConfig;
  }

  return { vatRate: defaultTaxRule.vatRate, price: defaultTaxRule.price };
}
