import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "~/providers/app";
import { authActions, getAuth } from "~/providers/store/auth";
import { employeeActions } from "~/providers/store/employee";

import { useSendWebEvent } from "../useSendWebEvent";

import type { apiContract } from "./api.client";
import { client, getAuthHeader } from "./api.client";
import { profilesKeys } from "./profiles";
import { suppliersKeys } from "./suppliers";
import { tillsKeys } from "./tills";

type AuthCardRequest = ClientInferRequest<typeof apiContract.v1.pos.auth.card>;

function useAuthEmployeeCard() {
  return useMutation({
    mutationFn: async (
      body: Pick<AuthCardRequest["body"], "cardHex" | "currency">
    ) => {
      const { schoolId, moduleId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      if (!body.cardHex) {
        throw new Error("Couldn’t recognize card. Verify your details.");
      }

      const res = await client.v1.pos.auth.card({
        body: {
          schoolId,
          cardHex: body.cardHex,
          currency: body.currency,
          moduleId,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    onSuccess: (res) => {
      employeeActions.setAuthEmployee({
        groupName: res.groupName,
        displayName: res.displayName,
        saldo: res.saldo,
        employeeUserId: res.employeeUserId,
        employeeChildId: res.employeeChildId,
        allowanceUsages: res.allowanceUsages,
      });
    },
  });
}

type ImpersonateRequest = ClientInferRequest<
  typeof apiContract.v1.pos.auth.till
>;

function useImpersonateTill() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      body: ImpersonateRequest["body"] & { tillId: string }
    ) => {
      const res = await client.v1.pos.auth.till({
        body,
        headers: getAuthHeader(),
      });
      if (res.status === 200) {
        return res.body;
      }
      throw res;
    },
    onSuccess: async (emulateInfo) => {
      authActions.impersonateTill({
        moduleId: emulateInfo.moduleId,
        userId: emulateInfo.moduleId,
        schoolId: emulateInfo.schoolId,
        tillId: emulateInfo.tillId,
        language: emulateInfo.language,
        partnerId: emulateInfo.partnerId,
      });

      // invalidate queries that depend on moduleId, schoolId, partnerId
      await queryClient.invalidateQueries({ queryKey: tillsKeys._def });
      await queryClient.invalidateQueries({ queryKey: profilesKeys._def });
      await queryClient.invalidateQueries({ queryKey: suppliersKeys._def });

      navigate("/profiles");
    },
  });
}

type LoginRequest = ClientInferRequest<typeof apiContract.v1.pos.auth.login>;

export function useLogin() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const sendWebEvent = useSendWebEvent();

  return useMutation({
    mutationFn: async (body: LoginRequest["body"]) => {
      const res = await client.v1.pos.auth.login({
        body,
        headers: { authorization: "" },
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    onSuccess: (data) => {
      authActions.login({
        token: data.token,
        partnerId: data.partnerId,
        language: data.language,
        country: data.country,
        isKanplaAdmin: data.isKanplaAdmin,
        locale: data.locale,
      });

      if (data.status === "assigned") {
        dispatch({
          type: "DEVICE_ASSIGN_TILL",
          payload: data.tillId,
        });

        sendWebEvent({
          type: "DEVICE_DATA_SET_REQUEST",
          payload: { tillId: data.tillId },
        });

        authActions.assignTill({
          moduleId: data.moduleId,
          userId: data.userId,
          schoolId: data.schoolId,
          tillId: data.tillId,
          language: data.language,
        });

        navigate("/profiles");
        return;
      }

      navigate("/license");
    },
  });
}

type AuthQrRequest = ClientInferRequest<typeof apiContract.v1.pos.auth.qr>;

function useAuthEmployeeQr() {
  return useMutation({
    mutationFn: async (
      body: Pick<AuthQrRequest["body"], "childId" | "currency">
    ) => {
      const { schoolId, moduleId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.auth.qr({
        body: {
          schoolId,
          moduleId,
          childId: body.childId,
          currency: body.currency,
        },
        headers: getAuthHeader(),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    onSuccess: (res) => {
      employeeActions.setAuthEmployee({
        groupName: res.groupName,
        displayName: res.displayName,
        saldo: res.saldo,
        employeeUserId: res.employeeUserId,
        employeeChildId: res.employeeChildId,
        allowanceUsages: res.allowanceUsages,
      });
    },
  });
}

export { useAuthEmployeeCard, useAuthEmployeeQr, useImpersonateTill };
