import { Intercom } from "@intercom/messenger-js-sdk";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";
import {
  Link,
  Navigate,
  Outlet,
  createBrowserRouter,
  useLocation,
  useRouteError,
} from "react-router-dom";

import { T, tx, useT } from "@repo/transifex";

import { Button } from "./components/Button";
import { LayoutError } from "./components/Layout";
import { useCurrentTill } from "./hooks/queries";
import { authActions } from "./providers/store/auth/actions";
import { useAuth, useAuthStatus } from "./providers/store/auth/hooks";
import { NotFoundPage } from "./routes/*/route";
import { DiningOptionsPage } from "./routes/__app.dining-options/route";
import { EmployeeCardPage } from "./routes/__app.employee-card/route";
import { PosEmailReceiptPage } from "./routes/__app.pos.$categoryId.$paymentType.email/route";
import { PosPrintReceiptPage } from "./routes/__app.pos.$categoryId.$paymentType.print/route";
import { DialogBasketProduct } from "./routes/__app.pos.$categoryId.basket-product.$basketProductId/route";
import { PosBillingPage } from "./routes/__app.pos.$categoryId.billing/route";
import { PosCardV3Page } from "./routes/__app.pos.$categoryId.card/route";
import { PosCashPage } from "./routes/__app.pos.$categoryId.cash/route";
import { PosCreditPage } from "./routes/__app.pos.$categoryId.credit/route";
import { CustomProducts } from "./routes/__app.pos.$categoryId.custom-product/route";
import { DiscountOrderPage } from "./routes/__app.pos.$categoryId.discount-order/route";
import { InvoicePage } from "./routes/__app.pos.$categoryId.invoice/route";
import { PosMobilePay } from "./routes/__app.pos.$categoryId.mobilepay/route";
import { ShopperNamePage } from "./routes/__app.pos.$categoryId.shopper-name/route";
import { PosSwishPage } from "./routes/__app.pos.$categoryId.swish/route";
import { WeightPage } from "./routes/__app.pos.$categoryId.weight-product.$productId/route";
import { PosCategoryProductsPage } from "./routes/__app.pos.$categoryId/route";
import { PosLayout } from "./routes/__app.pos/route";
import { VariantsPage } from "./routes/__app.product.$productId.variants/route";
import { SelectorsPage } from "./routes/__app.selectors/route";
import { PrintersPage } from "./routes/__app.settings.devices.printers/route";
import { ScalesPage } from "./routes/__app.settings.devices.scales/route";
import { TerminalsPage } from "./routes/__app.settings.devices.terminals/route";
import { DevicesPage } from "./routes/__app.settings.devices/route";
import { SessionReportsPage } from "./routes/__app.settings.reports.$tillId.$sessionId/route";
import { SessionTillsPage } from "./routes/__app.settings.reports.$tillId/route";
import { ReportsPage } from "./routes/__app.settings.reports/route";
import { TillPage } from "./routes/__app.settings.tills.$tillId/route";
import { TillSessionPage } from "./routes/__app.settings.tills.$tillId_.$sessionId/route";
import { TillSessionClosePage } from "./routes/__app.settings.tills.$tillId_.$sessionId_.close/route";
import { TillSessionTransactionPage } from "./routes/__app.settings.tills.$tillId_.$sessionId_.transactions/route";
import { TillSessionOpenPage } from "./routes/__app.settings.tills.$tillId_.open/route";
import { TillsPage } from "./routes/__app.settings.tills._index/route";
import { TransactionsPage } from "./routes/__app.settings.transactions/route";
import { SettingsPage } from "./routes/__app.settings/route";
import { AppLayout } from "./routes/__app/route";
import { AdminPage } from "./routes/admin/route";
import { LicensePage } from "./routes/license/route";
import { MenuPage } from "./routes/menu/route";
import { OfflinePage } from "./routes/offline/route";
import { ProfilePinPage } from "./routes/profiles.$profileId/route";
import { ProfilesPage } from "./routes/profiles/route";
import { LoginPage } from "./routes/root/route";
import { SupportPage } from "./routes/support/route";
import { isApiError } from "./utils/api";
import { INTERCOM_APP_ID } from "./utils/constants";
import { setSentryTagSession, setSentryTagTill } from "./utils/sentry";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation();
  const status = useAuthStatus();

  if (status === "none") {
    return <Navigate replace to="/" />;
  }

  if (status === "user" && !pathname.includes("license")) {
    return <Navigate replace to="/license" />;
  }

  if (
    status === "till" &&
    !pathname.includes("profiles") &&
    pathname !== "/admin"
  ) {
    return <Navigate replace to="/profiles" />;
  }

  return children;
};

const ErrorPage = () => {
  const error = useRouteError();

  const t = useT();

  const message = isApiError(error)
    ? error.body.message
    : t("An error occurred");

  return (
    <LayoutError>
      <div className="flex flex-col gap-2 text-center">
        <T _str={message} />

        <Link to="/menu">
          <Button>
            <T _str="Go to Menu" />
          </Button>
        </Link>

        <Button
          onClick={() => {
            location.reload();
          }}
          variant="info"
        >
          <T _str="Reload" />
        </Button>

        <Button
          onClick={() => {
            authActions.logout();
          }}
          variant="danger"
        >
          <T _str="Log out" />
        </Button>
      </div>
    </LayoutError>
  );
};

const RootLayout = () => {
  const location = useLocation();
  const posthog = usePostHog();

  const { language, userId, tillId, schoolId, partnerId } = useAuth();
  const { data: till } = useCurrentTill();

  if (INTERCOM_APP_ID && userId) {
    Intercom({
      app_id: INTERCOM_APP_ID,
      region: "eu",
      hide_default_launcher: true,
      user_id: userId,
      till_id: tillId,
      location_id: schoolId,
      partner_id: partnerId,
    });
  }

  useEffect(() => {
    const browserLanguage = window.navigator.language?.split("-")[0];
    tx.setCurrentLocale(language || browserLanguage);
  }, [language]);

  // Identify till in PostHog and Sentry
  useEffect(() => {
    if (posthog && till) {
      setSentryTagTill(till.id);
      setSentryTagSession(till.sessionId);

      posthog.identify(till.id, {
        name: till.name,
      });
      posthog.group("school", till.schoolId);
    }
  }, [till, posthog]);

  // Track pageview in PostHog (SPA)
  useEffect(() => {
    if (posthog) {
      posthog.capture("$pageview", {
        $current_url: location.pathname,
      });
    }
  }, [location.pathname, posthog]);

  return (
    <div className="h-screen w-full">
      <Outlet />
    </div>
  );
};

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router: ReturnType<typeof sentryCreateBrowserRouter> =
  sentryCreateBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <LoginPage />,
        },
        {
          path: "admin",
          element: (
            <ProtectedRoute>
              <AdminPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "license",
          element: (
            <ProtectedRoute>
              <LicensePage />
            </ProtectedRoute>
          ),
        },
        {
          path: "profiles",
          element: (
            <ProtectedRoute>
              <ProfilesPage />
            </ProtectedRoute>
          ),
          children: [
            {
              path: ":profileId",
              element: <ProfilePinPage />,
            },
          ],
        },
        {
          element: (
            <ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>
          ),
          children: [
            {
              path: "menu",
              element: <MenuPage />,
            },
            {
              path: "support",
              element: <SupportPage />,
            },
            {
              path: "settings",
              element: <SettingsPage />,
              children: [
                {
                  path: "devices",
                  element: <DevicesPage />,
                  children: [
                    {
                      path: "printers",
                      element: <PrintersPage />,
                    },
                    {
                      path: "scales",
                      element: <ScalesPage />,
                    },
                    {
                      path: "terminals",
                      element: <TerminalsPage />,
                    },
                  ],
                },
                {
                  path: "reports",
                  element: <ReportsPage />,
                  children: [
                    {
                      path: ":tillId",
                      element: <SessionTillsPage />,
                      children: [
                        {
                          path: ":sessionId",
                          element: <SessionReportsPage />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "tills",
                  element: <TillsPage />,
                },
                {
                  path: "tills/:tillId",
                  element: <TillPage />,
                },
                {
                  path: "tills/:tillId/open",
                  element: <TillSessionOpenPage />,
                },
                {
                  path: "tills/:tillId/:sessionId",
                  element: <TillSessionPage />,
                },
                {
                  path: "tills/:tillId/:sessionId/close",
                  element: <TillSessionClosePage />,
                },
                {
                  path: "tills/:tillId/:sessionId/transactions",
                  element: <TillSessionTransactionPage />,
                },

                {
                  path: "transactions",
                  element: <TransactionsPage />,
                },
              ],
            },
            {
              path: "pos",
              element: <PosLayout />,
              children: [
                {
                  index: true,
                  element: <Navigate replace to="/pos/all" />,
                },
                {
                  path: ":categoryId",
                  element: <PosCategoryProductsPage />,
                  children: [
                    {
                      path: "billing",
                      element: <PosBillingPage />,
                    },
                    {
                      path: "cash",
                      element: <PosCashPage />,
                    },
                    {
                      path: "card",
                      element: <PosCardV3Page />,
                    },
                    {
                      path: "credit",
                      element: <PosCreditPage />,
                    },
                    {
                      path: "mobilepay",
                      element: <PosMobilePay />,
                    },
                    {
                      path: "swish",
                      element: <PosSwishPage />,
                    },
                    {
                      path: ":paymentType/email",
                      element: <PosEmailReceiptPage />,
                    },
                    {
                      path: ":paymentType/print",
                      element: <PosPrintReceiptPage />,
                    },
                    {
                      path: "custom-product",
                      element: <CustomProducts />,
                    },
                    {
                      path: "shopper-name",
                      element: <ShopperNamePage />,
                    },
                    {
                      path: "invoice",
                      element: <InvoicePage />,
                    },
                    {
                      path: "discount-order",
                      element: <DiscountOrderPage />,
                    },
                    {
                      path: "basket-product/:basketProductId",
                      element: <DialogBasketProduct />,
                    },
                    {
                      path: "weight-product/:productId",
                      element: <WeightPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: "product/:productId/variants",
              element: <VariantsPage />,
            },
            {
              path: "dining-options",
              element: <DiningOptionsPage />,
            },
            {
              path: "employee-card",
              element: <EmployeeCardPage />,
            },
            {
              path: "selectors",
              element: <SelectorsPage />,
            },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
    {
      path: "offline",
      element: <OfflinePage />,
    },
  ]);

export { router };
