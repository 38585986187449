import {
  faFileInvoice,
  faPlus,
  faUser,
  faWarning,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getErrorMessage, priceFormatter, round } from "@repo/system";
import { T } from "@repo/transifex";
import type { PaymentProvider } from "@repo/types";
import { clsx } from "clsx";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import ShortUniqueId from "short-unique-id";

import { Button } from "~/components";
import { OrderCreateErrorDialog } from "~/components/OrderCreateErrorDialog";
import { useSendWebEvent } from "~/hooks";
import {
  useAbortTransaction,
  useCurrentTill,
  useOrderCardReimbursementCreate,
  useOrderCreate,
  usePaymentCreate,
  usePrintOrderReceipt,
} from "~/hooks/queries";
import useOrderCardCreateV3 from "~/hooks/queries/useOrderCardCreateV3.ts";
import {
  useBasketProducts,
  useDeviceData,
  useSplitPaymentFlow,
} from "~/providers/app";
import { useAuth } from "~/providers/store/auth";
import {
  useBasketWithCategories,
  useDiningOption,
  useOrderDiscount,
  useShopperName,
} from "~/providers/store/basket";
import { useEmployee } from "~/providers/store/employee";
import { isApiError } from "~/utils/api";
import { calculateAmountForSaldo } from "~/utils/basket";

import { DiningOptionSwitch } from "./DiningOptionSwitch";
import { PaymentButtons } from "./PaymentButtons";
import { Price } from "./Price";

export const BasketFooter = () => {
  const { pathname } = useLocation();

  const [, setSearchParams] = useSearchParams();

  const { os, osVersion } = useDeviceData();

  const diningOption = useDiningOption();
  const shopperName = useShopperName();

  const {
    groupName,
    employeeUserId,
    employeeChildId,
    saldo: unitSaldo,
  } = useEmployee();

  const { locale } = useAuth();
  const { mutate: createOrder, status, error, reset } = useOrderCreate();
  const { mutate: abortTransaction } = useAbortTransaction();

  const {
    mutate: createCardOrderV3,
    status: cardStatusV3,
    reset: cardResetV3,
    error: cardErrorV3,
  } = useOrderCardCreateV3({
    abortTransactionCallback: ({ orderId, serviceId, terminalId }) => {
      abortTransaction({ orderId, serviceId, terminalId });
    },
  });

  const { mutate: payOrder } = usePaymentCreate();

  const {
    mutate: createCardReimbursementOrder,
    status: cardReimbursementStatus,
    error: cardReimbursementError,
    reset: cardReimbursementReset,
  } = useOrderCardReimbursementCreate({
    abortTransactionCallback: ({ orderId, serviceId, terminalId }) => {
      abortTransaction({ orderId, serviceId, terminalId });
    },
  });

  const sendWebEvent = useSendWebEvent();

  const { mutate: createReceipt } = usePrintOrderReceipt();

  const {
    productsInBasket,
    totalUnit,
    totalUnitDiscounted,
    isEmpty: isBasketEmpty,
    meta,
  } = useBasketProducts();
  const basketWithCategories = useBasketWithCategories();

  const { data: till, status: tillStatus } = useCurrentTill();

  const {
    isActive: isSplitPaymentActive,
    loading: isSplitPaymentLoading,
    orderId: splitPaymentOrderId,
    unitReceived,
  } = useSplitPaymentFlow();

  const orderDiscount = useOrderDiscount();

  const handleCreateOrder = (paymentMethod: PaymentProvider) => {
    const info = diningOption
      ? {
          textInput: {
            [diningOption.inputId]: diningOption.value,
          },
        }
      : undefined;

    if (paymentMethod === "card" || paymentMethod === "swish") {
      if (!os || !osVersion) throw new Error("OS or OS Version not provided");
      if (!till) throw new Error("Till not found");
      if (!till.sessionId) throw new Error("Till is not open");

      const uid = new ShortUniqueId({ length: 10 });
      const orderId = `${till.id.substring(0, 4)}-${till.sessionId.substring(
        0,
        4
      )}-${uid.rnd()}`;

      if (totalUnit < 0) {
        if (paymentMethod === "swish")
          throw new Error(
            "Swish payment method not supported for negative amount"
          );

        createCardReimbursementOrder(
          {
            orderId,
            paymentMethod,
            basket: basketWithCategories,
            groupName: groupName || undefined,
            deviceInfo: { os, osVersion },
          },
          {
            onSuccess: (_, { orderId }) => {
              createReceipt({ orderId });
            },
          }
        );

        return;
      }

      createCardOrderV3({
        orderId: splitPaymentOrderId ?? orderId,
        paymentMethod,
        basket: basketWithCategories,
        groupName: groupName || undefined,
        employeeUserId,
        employeeChildId,
        paidWithSaldoUnit: totalPrice.payWithSaldo,
        deviceInfo: { os, osVersion },
        unitCashReceived: unitReceived,
        info,
        customShopperName: shopperName || undefined,
      });
    } else {
      // Split payment flow - order already created
      if (isSplitPaymentActive) {
        return;
      }

      createOrder(
        {
          paymentMethod,
          basket: basketWithCategories,
          groupName: groupName || undefined,
          employeeUserId,
          employeeChildId,
          paidWithSaldoUnit: totalPrice.payWithSaldo,
          info,
          customShopperName: shopperName || undefined,
        },
        {
          onSuccess: (order) => {
            setSearchParams({ orderId: order.id });

            // Reimbursement flow
            if (paymentMethod === "cash" && totalUnit < 0 && os && osVersion) {
              payOrder({
                orderId: order.id,
                deviceInfo: { os, osVersion },
                paymentProvider: "cash",
                unitCashReceived: totalUnit,
              });
              sendWebEvent({ type: "CASH_DRAWER_OPEN_REQUEST" });
              createReceipt({ orderId: order.id });
            }
          },
        }
      );
    }
  };

  const isSubmitDisabled =
    productsInBasket.length === 0 ||
    status === "pending" ||
    cardStatusV3 === "pending" ||
    tillStatus === "pending" ||
    cardReimbursementStatus === "pending" ||
    isSplitPaymentLoading;

  const totalPrice = calculateAmountForSaldo(totalUnitDiscounted, unitSaldo);

  const unitOrderDiscount =
    orderDiscount.type === "currency"
      ? orderDiscount.amount
      : totalUnit * (orderDiscount.amount / 100);

  const totalPriceSaldo = priceFormatter({
    value: totalPrice.payWithSaldo,
    currency: meta.currency,
    locale,
  });

  const totalPriceFormatted = priceFormatter({
    value:
      totalUnit - unitReceived - totalPrice.payWithSaldo - unitOrderDiscount,
    currency: meta.currency,
    locale,
  });

  const cashReceivedFormatted = priceFormatter({
    value: -unitReceived,
    currency: meta.currency,
    locale,
  });

  const orderDiscountLabel =
    orderDiscount.type === "currency"
      ? priceFormatter({
          value: unitOrderDiscount,
          currency: meta.currency,
          locale,
        })
      : `${round(orderDiscount.amount, 2)} %`;

  const showSaldo =
    typeof unitSaldo === "number" &&
    unitSaldo > 0 &&
    totalPrice.payWithSaldo > 0;

  // Order and card payment error
  const hasCardError =
    cardStatusV3 === "error" && isSignificantError(cardErrorV3);

  // Order and card reimbursement error
  const hasCardReimbursementError = cardReimbursementStatus === "error";

  // Order and all other payment methods but card error
  const hasOrderError = status === "error";

  if (tillStatus !== "success") return null;

  const isInvoiceVisible =
    till.type === "attended" &&
    till.paymentProviders.includes("billing-selector") &&
    !(!isBasketEmpty && totalUnit === 0);

  const isOrderDiscountVisible =
    till.type === "attended" && !(!isBasketEmpty && totalUnit <= 0);

  const attendedDiningOption =
    till.type === "attended" && Boolean(diningOption);

  const selfServiceDiningOption =
    till.type === "self-service" && Boolean(diningOption);

  return (
    <>
      {till.config.hasCustomNameInput && !employeeUserId ? (
        <Link className="w-full p-4" to={`${pathname}/shopper-name`}>
          <Button className="w-full" variant="secondary">
            {shopperName ? (
              <div className="flex items-center gap-2">
                <FontAwesomeIcon icon={faUser} />
                <p>{shopperName}</p>
              </div>
            ) : (
              <T _str="Add name" />
            )}
          </Button>
        </Link>
      ) : null}

      <div className="flex w-full justify-center">
        {selfServiceDiningOption ? (
          <div className="flex-1">
            <DiningOptionSwitch tillType="self-service" />
          </div>
        ) : null}

        {isOrderDiscountVisible ? (
          <Link
            className={clsx({
              "pointer-events-none": isSubmitDisabled || isSplitPaymentActive,
            })}
            to={`${pathname}/discount-order`}
          >
            <Button
              disabled={
                isSubmitDisabled ||
                isSplitPaymentActive ||
                productsInBasket.filter((p) => p.type !== "custom").length === 0
              }
              icon={faPlus}
              variant="light"
            >
              <T _str="Discount" />
            </Button>
          </Link>
        ) : null}

        {isInvoiceVisible ? (
          <Link
            className={clsx({
              "pointer-events-none": isSubmitDisabled || isSplitPaymentActive,
            })}
            to={`${pathname}/invoice`}
          >
            <Button
              disabled={isSubmitDisabled || isSplitPaymentActive}
              icon={faFileInvoice}
              variant="light"
            >
              <T _str="Invoice" />
            </Button>
          </Link>
        ) : null}
      </div>

      <div className="mx-4 flex border-t border-divider-main py-3">
        {attendedDiningOption ? (
          <div className="flex-1">
            <DiningOptionSwitch tillType="attended" />
          </div>
        ) : null}

        {!till.moduleConfig.hidePrices ? (
          <div className="flex flex-1 flex-col gap-3">
            <div>
              {showSaldo ? (
                <p className="text-info-dark">
                  <FontAwesomeIcon className="mr-2" icon={faWarning} />
                  <T
                    _str="We subtract {amount} from your balance"
                    amount={totalPriceSaldo}
                  />
                </p>
              ) : null}
              <Price
                column={attendedDiningOption}
                hidden={!showSaldo}
                title="Saldo"
                type="secondary"
                value={`- ${totalPriceSaldo}`}
              />
            </div>

            <Price
              column={attendedDiningOption}
              hidden={!isSplitPaymentActive}
              title="Cash"
              type="secondary"
              value={cashReceivedFormatted}
            />

            <Price
              column={attendedDiningOption}
              hidden={!orderDiscount.amount}
              title="Discount"
              type="secondary"
              value={`- ${orderDiscountLabel}`}
            />

            <Price
              column={attendedDiningOption}
              title={
                !isSplitPaymentActive || orderDiscount.amount
                  ? "Total"
                  : "Remaining"
              }
              type="primary"
              value={totalPriceFormatted}
            />
          </div>
        ) : null}
      </div>

      <PaymentButtons
        isSubmitDisabled={isSubmitDisabled}
        onCreate={handleCreateOrder}
        restPrice={
          totalPrice.payWithOther && typeof unitSaldo === "number"
            ? totalPriceFormatted
            : null
        }
      />

      <OrderCreateErrorDialog
        error={inferOrderErrorMessage([
          error,
          cardErrorV3,
          cardReimbursementError,
        ])}
        open={hasOrderError || hasCardError || hasCardReimbursementError}
        reset={() => {
          if (hasOrderError) reset();
          if (hasCardError) {
            cardResetV3();
          }
          if (hasCardReimbursementError) cardReimbursementReset();
        }}
      />
    </>
  );
};

function inferOrderErrorMessage(errors: unknown[]) {
  for (const error of errors) {
    if (!error) continue;

    if (isApiError(error)) {
      return error.body.message;
    }

    return getErrorMessage(error);
  }

  return "Could not create order";
}

const SKIPPABLE_ERRORS = [
  "Payment terminal responded with: Aborted, 104 Merchant cancelled tx",
];

/**
 * Function that determines if an error is significant enough to show to the user
 * @param error - The error to check
 * @returns - true if the error is significant, false if we can avoid showing it
 */
function isSignificantError(error: Error) {
  if (SKIPPABLE_ERRORS.includes(inferOrderErrorMessage([error]))) return false;

  return true;
}
